var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.device && _vm.user.information
    ? _c("section", [
        _c(
          "div",
          {
            key: _vm.$route.fullPath,
            class: { showFolders: _vm.showFolders },
            attrs: { id: "wrapper" },
          },
          [
            _c(
              "div",
              { staticClass: "h-100", attrs: { id: "inbox-sidebar" } },
              [
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showFolders,
                      expression: "!showFolders",
                    },
                  ],
                  staticClass: "fal fa-chevron-right nav-icon clickable",
                  on: {
                    click: function ($event) {
                      _vm.showFolders = true
                    },
                  },
                }),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFolders,
                      expression: "showFolders",
                    },
                  ],
                  staticClass: "fal fa-chevron-left nav-icon clickable",
                  on: {
                    click: function ($event) {
                      _vm.showFolders = false
                    },
                  },
                }),
                _c("MessagesMenu", {
                  attrs: {
                    functionbox_uuid: _vm.functionbox_uuid,
                    user_uuid: _vm.user_uuid,
                    sefosEnabled: _vm.sefosEnabled,
                    showFolders: _vm.showFolders,
                    "is-mobile": _vm.device.isMobile,
                  },
                  on: { closeFolders: _vm.closeFolders },
                }),
                _c(
                  "div",
                  { staticClass: "email-text mt-2" },
                  [
                    _vm.user.information.is_admin > 0
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "no-border",
                            attrs: {
                              to: {
                                name: "Organisation.Settings.FunctionBox",
                                params: {
                                  organisation_id:
                                    _vm.user.information.organisation.id,
                                },
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fal fa-cog" }),
                            _vm.showFolders
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("FUNCTIONBOX.MANAGE"))),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { attrs: { id: "inbox-content" } }, [
              _vm.message_uuid == ""
                ? _c("div", { staticClass: "w-100" }, [
                    _vm.showInbox
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "mb-2 ml-2" }, [
                              _vm.sefosEnabled
                                ? _c("span", [
                                    _c("img", {
                                      staticClass: "icon",
                                      attrs: {
                                        src: "/img/message_type/message.png",
                                        alt: _vm.$t("MESSAGES.TYPE.message"),
                                      },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.boxInformation.name) +
                                        ", " +
                                        _vm._s(_vm.boxInformation.email) +
                                        "\n              "
                                    ),
                                    _vm.boxInformation.fax ||
                                    _vm.boxInformation.sdk
                                      ? _c("span", [_vm._v(", ")])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm.boxInformation.fax
                                ? _c("span", [
                                    _c("img", {
                                      staticClass: "icon",
                                      attrs: {
                                        src: "/img/message_type/fax-message.png",
                                        alt: _vm.$t(
                                          "MESSAGES.TYPE.fax-message"
                                        ),
                                      },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.boxInformation.fax) +
                                        "\n              "
                                    ),
                                    _vm.boxInformation.sdk
                                      ? _c("span", [_vm._v(", ")])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm.boxInformation.sdk
                                ? _c("span", [
                                    _c("img", {
                                      staticClass: "icon",
                                      attrs: {
                                        src: "/img/message_type/sdk-message.png",
                                        alt: _vm.$t(
                                          "MESSAGES.TYPE.sdk-message"
                                        ),
                                      },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.boxInformation.sdk)
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c(
                              "b-form",
                              {
                                key:
                                  "ListMessageForm_" + this.listMessageKeyIndex,
                                attrs: { inline: "" },
                              },
                              [
                                _vm.ShowAddressbook
                                  ? _c("AddressBook", {
                                      attrs: {
                                        user_uuid: _vm.user_uuid,
                                        functionbox_uuid: _vm.functionbox_uuid,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ onClick }) {
                                              return [
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "no-border",
                                                    attrs: {
                                                      title:
                                                        _vm.$t(
                                                          "ADDRESSBOOK.TITLE"
                                                        ),
                                                    },
                                                    on: { click: onClick },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fal fa-address-book",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1847607087
                                      ),
                                    })
                                  : _vm._e(),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "no-border",
                                    attrs: { title: _vm.$t("REFRESH") },
                                    on: { click: _vm.refreshList },
                                  },
                                  [_c("i", { staticClass: "fal fa-sync" })]
                                ),
                                _c("AddMessage", {
                                  attrs: {
                                    functionbox_uuid: _vm.functionbox_uuid,
                                    user_uuid: _vm.user_uuid,
                                    message: _vm.message,
                                  },
                                  on: {
                                    Close: _vm.CloseAddMessage,
                                    Changed: _vm.Changed,
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "sr-only",
                                    attrs: { for: "inline-form-input-search" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                                ),
                                _c("b-form-input", {
                                  staticClass:
                                    "i-100 searchText hidden-mobile ml-2",
                                  attrs: {
                                    trim: "",
                                    id: "inline-form-input-search",
                                    placeholder: _vm.$t("SEARCH"),
                                  },
                                  on: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      $event.preventDefault()
                                    },
                                  },
                                  model: {
                                    value: _vm.searchText,
                                    callback: function ($$v) {
                                      _vm.searchText = $$v
                                    },
                                    expression: "searchText",
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "sr-only",
                                    attrs: { for: "inline-form-input-limit" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("LIMIT")))]
                                ),
                                _c(
                                  "b-form-select",
                                  {
                                    staticClass: "limit hidden-mobile",
                                    attrs: {
                                      id: "inline-form-input-limit",
                                      name: "messages_limit",
                                    },
                                    model: {
                                      value: _vm.limit,
                                      callback: function ($$v) {
                                        _vm.limit = $$v
                                      },
                                      expression: "limit",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: "10" } },
                                      [_vm._v("10")]
                                    ),
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: "25" } },
                                      [_vm._v("25")]
                                    ),
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: "45" } },
                                      [_vm._v("45")]
                                    ),
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: "100" } },
                                      [_vm._v("100")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.isAdminForFunctionBox == 1
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "no-border ml-2",
                                            on: {
                                              click: function ($event) {
                                                _vm.showEditFunctionBox = true
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fal fa-cog",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "b-sidebar",
                                          {
                                            attrs: {
                                              id: "user-sidebar",
                                              "sidebar-class": "big_sidebar",
                                              right: "",
                                              backdrop: "",
                                              "no-slide": "",
                                              shadow: "",
                                            },
                                            model: {
                                              value: _vm.showEditFunctionBox,
                                              callback: function ($$v) {
                                                _vm.showEditFunctionBox = $$v
                                              },
                                              expression: "showEditFunctionBox",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "px-3 py-2" },
                                              [
                                                _c("FunctionBoxPage", {
                                                  key: _vm.functionbox_uuid,
                                                  attrs: {
                                                    "edit-users": "",
                                                    functionbox_uuid:
                                                      _vm.functionbox_uuid,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "hidden-not-mobile w-100 mt-2",
                                  },
                                  [
                                    _c(
                                      "b-form",
                                      { attrs: { inline: "" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "sr-only",
                                            attrs: {
                                              for: "inline-form-input-search",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "ml-2 i-100 searchText",
                                          attrs: {
                                            trim: "",
                                            placeholder: _vm.$t("SEARCH"),
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                            },
                                          },
                                          model: {
                                            value: _vm.searchText,
                                            callback: function ($$v) {
                                              _vm.searchText = $$v
                                            },
                                            expression: "searchText",
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "sr-only",
                                            attrs: {
                                              for: "list-limit-messages",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("LIMIT")))]
                                        ),
                                        _c(
                                          "b-form-select",
                                          {
                                            staticClass: "limit",
                                            attrs: {
                                              id: "list-limit-messages",
                                              name: "messages_limit",
                                            },
                                            model: {
                                              value: _vm.limit,
                                              callback: function ($$v) {
                                                _vm.limit = $$v
                                              },
                                              expression: "limit",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-select-option",
                                              { attrs: { value: "10" } },
                                              [_vm._v("10")]
                                            ),
                                            _c(
                                              "b-form-select-option",
                                              { attrs: { value: "25" } },
                                              [_vm._v("25")]
                                            ),
                                            _c(
                                              "b-form-select-option",
                                              { attrs: { value: "45" } },
                                              [_vm._v("45")]
                                            ),
                                            _c(
                                              "b-form-select-option",
                                              { attrs: { value: "100" } },
                                              [_vm._v("100")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("router-view", {
                              key: "ListMessage_" + this.listMessageKeyIndex,
                              attrs: {
                                searchText: _vm.searchText,
                                functionbox_uuid: _vm.functionbox_uuid,
                                user_uuid: _vm.user_uuid,
                                update_message_uuid: _vm.update_message_uuid,
                                email: _vm.email,
                                limit: _vm.limit,
                              },
                              on: {
                                "update:searchText": function ($event) {
                                  _vm.searchText = $event
                                },
                                "update:search-text": function ($event) {
                                  _vm.searchText = $event
                                },
                                changeUpdateMessageUuid:
                                  _vm.changeUpdateMessageUuid,
                                viewingMessage: _vm.viewingMessage,
                                viewingNotification: _vm.viewingNotification,
                                draftMessage: _vm.DraftMessage,
                                "update:limit": function ($event) {
                                  _vm.limit = $event
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.message_uuid != ""
                ? _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _vm.conversation_message_uuid == ""
                        ? _c("Message", {
                            attrs: {
                              message_uuid: _vm.message_uuid,
                              "show-close-btn": "",
                              functionbox_uuid: _vm.functionbox_uuid,
                              user_uuid: _vm.user_uuid,
                            },
                            on: {
                              closeMessage: _vm.closeMessage,
                              showConversation: _vm.showConversation,
                            },
                          })
                        : _vm._e(),
                      _vm.conversation_message_uuid != ""
                        ? _c("Conversation", {
                            attrs: {
                              message_uuid: _vm.conversation_message_uuid,
                              inbox: true,
                              functionbox_uuid: _vm.functionbox_uuid,
                              "show-close-btn": "",
                              user_uuid: _vm.user_uuid,
                            },
                            on: {
                              gotoMessage: _vm.gotoMessage,
                              closeConversation: _vm.closeConversation,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }